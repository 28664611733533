import React, { useContext, useState, useEffect } from 'react';
import qs from 'qs';
import axios from 'axios';
import Modal from './Modal'
import random from '../utils/random';
import { Context as UserContext } from './UserContext';
import { Context as LeaveContext } from './LeaveContext';


export default function ChangePassword() {
  const {
    Password,
    forceChangePassword,
    toggleForceChangePassword,
    changePassword,
  } = useContext(UserContext)
  const { fetchData } = useContext(LeaveContext)

  const [state, setState] = useState({
    Loading: false,
    OpenChangePassword: false,
    CurrentPassword: '',
    NewPassword: '',
    ConfirmPassword: '',
  })

  useEffect(() => {
    if (Password && !toggleForceChangePassword) {
      if (!validatePassword(Password)) {
        forceChangePassword()
      }
    }
  }, [Password, forceChangePassword, toggleForceChangePassword])

  const ChangePassword = (e) => {
    e.preventDefault()
    setState(prev => ({ ...prev, Loading: true }))

    let Error = '';

    if (!validatePassword(state.NewPassword)) {
      Error = 'Invalid Password';
    }

    if (state.NewPassword !== state.ConfirmPassword) {
      Error = 'Passwords do not match'
    }

    if (state.CurrentPassword.length === 0 || state.NewPassword.length === 0 || state.ConfirmPassword.length === 0) {
      Error = 'Please fill out all three password fields';
    }

    if (Error.length > 0) {
      setState(prev => ({ ...prev, Error, Loading: false }))
      return false;
    }

    // Attempt to re login with stored credentials
    const data = qs.stringify({
      currentPassword: state.CurrentPassword,
      newPassword: state.NewPassword,
    });
    // Post to datasource with credentials
    const ChangePasswordURL = 'https://apps.cbcins.com/api/Service/LeaveRequest/ChangePassword'
    // if(window.ChangePasswordURL)
    //   ChangePasswordURL = window.ChangePasswordURL
    axios
      .post(`${ChangePasswordURL}?V=${random()}`, data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      })
      .then(() => {
        // Change the users password
        changePassword(state.NewPassword);
        FetchMemberData()
        setState(prev => ({ ...prev, Loading: false, Error: '' }))
      })
      .catch(() => {
        setState(prev => ({ ...prev, Error: 'Error', Loading: false }))
      });
  }
  const FetchMemberData = () => {
    // API call to datasource for user data.
    const LeaveRequestURL = 'https://apps.cbcins.com/api/Service/LeaveRequest/Add'
    // Example of using a variable inside of the HTML file to use inside of a react application
    // if(window.LeaveRequestURL)
    //   LeaveRequestURL = window.LeaveRequestURL
    axios
      .get(`${LeaveRequestURL}?V=${random()}`)
      .then((res) => {
        // store the user data into the leaveRequest Store
        fetchData(res.data)
      })
  }
  const validatePassword = (password) => {
    const re = /^(?=.*[A-Z]){1}(?=.*[a-z]){1}(?=.*[!@#$%&*]){1}(?=.*[0-9]){1}(?=.{8})/;
    return re.test(String(password))
  }
  const handlePasswordChange = (e) => {
    const NewState = {}
    NewState[e.target.id] = e.target.value
    setState(prev => ({ ...prev, ...NewState, Error: '' }))
  }

  return (
    <Modal status={toggleForceChangePassword}>
      {/* <link href="/styles/Structure.css" rel="stylesheet" type="text/css" />
      <link href="/styles/Styles.css" rel="stylesheet" type="text/css" />
      <link href="/styles/popups.css" rel="stylesheet" type="text/css" /> */}

      <div id="popup" style={{ width: 830, height: 560 }}>
        <div id="popheader">Change Password Form</div>
        <div id="popbody">
          <p style={{ fontSize: '1.25em' }}>Please change your password using the form below. Passwords need to contain at least 1 uppercase, 1 lower case, 1 number and 1 special character (example: !@#$%&*) and be at least 8 characters long.</p>
          <p>&nbsp;</p>
          <form className="LeaveFormEntry" style={{ paddingLeft: '1em', fontSize: '1.25em' }}>
            <div className="formDiv">Old Password:
              <input onChange={handlePasswordChange} type="text" id="CurrentPassword" />
            </div>
            <br className="clear" />
            <div className="formDiv" style={{ paddingTop: '.5em' }}>New Password:
              <input onChange={handlePasswordChange} type="text" id="NewPassword" />
            </div>
            <br className="clear" />
            <div className="formDiv" style={{ paddingTop: '.5em' }}>New Password Confirm:
              <input onChange={handlePasswordChange} type="text" id="ConfirmPassword" />
            </div>
            <br className="clear" />
          </form>
          <p className="clear">&nbsp;</p>
          <div style={{ fontSize: 18 }} className="errorMsgs">
            <span>{state.Error}</span>
          </div>
          <p className="clear">&nbsp;</p>
          <div id="button" style={{ float: 'right' }}>
            <a
              disabled={state.Loading}
              onClick={ChangePassword}
              href="This link deletes the currently selected item"
              id="button"
              style={{
                width: '6em', fontSize: '1.24em', textAlign: 'center', background: '#C12030',
              }}
            >
              {state.Loading ? 'Loading...' : 'Submit'}
            </a>
          </div>
        </div>
      </div>
    </Modal>
  )
}
