import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment'
import axios from 'axios';
import qs from 'qs';
import { useStaticQuery, graphql } from 'gatsby'
import navigate from '../utils/navigate'
import random from '../utils/random';
import Modal from './Modal'
import { Context as UserContext } from './UserContext';

export default function AlertPopup() {
  const data = useStaticQuery(graphql`
    query AlertPopUpComoponent {
      site {
        siteMetadata {
          home
        }
      }
    }
  `)
  const HomeUrl = data.site.siteMetadata.home

  const {
    login,
    logout,
    token,
    maxAge,
    Password,
    Username,
  } = useContext(UserContext);

  const [state, setState] = useState({
    Warning: false,
    TimeOut: false,
    Logged: false,
  })

  useEffect(() => {
    setState(prev => ({ ...prev, Logged: !!token }))
  }, [token])

  useEffect(() => {
    const interval = setInterval(() => {
      if (state.Logged) {
        // Get the Diffrence in minutes
        const MinutesToExpiration = moment(maxAge).diff(moment().utc(), 'minutes')
        // 15 minutes or less show the warning
        if (MinutesToExpiration < 16 && MinutesToExpiration > 0) {
          setState(prev => ({ ...prev, Warning: MinutesToExpiration, TimeOut: false }))
        } else if (MinutesToExpiration <= 0) { // Timed out, log the user off
          setState((prev) => {
            logout()
            return { ...prev, Warning: 0, TimeOut: true }
          })
        }
      }
    }, 1000 * 60 * 3)

    return () => {
      clearInterval(interval)
    }
  }, [logout, maxAge, state.Logged])

  const ClosePopUp = () => {
    // If they are signout out then make sure they are redirected when closing the popup
    if (state.TimeOut) {
      logout()
      navigate(HomeUrl);
    }
    // close the popup
    setState({ Warning: false, TimeOut: false })
  }
  const Logout = (e) => {
    e.preventDefault()
    logout()
    navigate(HomeUrl);
  }
  const ReLogin = (e) => {
    e.preventDefault()

    // If the user returned to the website inside of their session time and hit the warning,
    // they will not be able to automaticly log back in,
    // beceause we are not storing the user's Username and Password inside of the cookie
    if (!Username || !Password) {
      logout()
      navigate(HomeUrl);
    }

    // Attempt to re login with stored credentials
    const data = qs.stringify({
      userName: Username,
      password: Password,
      confirmPassword: Password,
      grant_type: 'password',
    });
    // Post to datasource with credentials
    const LoginURL = 'https://apps.cbcins.com/Token?AccountID=1'
    // if(window.LoginURL)
    //   LoginURL = window.LoginURL
    axios
      .post(`${LoginURL}&V=${random()}`, data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      })
      .then((res) => {
        // Log the user in
        login(res.data.access_token, res.data.expires_in, Username, Password);
        setState({ Warning: false })
      })
      .catch(() => {
        // Any failure will result in force logout
        logout()
        navigate(HomeUrl);
      });
  }

  if (state.Warning !== 0) {
    return (
      <Modal status={state.Warning} closeControl={ClosePopUp}>
        {/* <link href="/styles/Structure.css" rel="stylesheet" type="text/css" />
        <link href="/styles/Styles.css" rel="stylesheet" type="text/css" />
        <link href="/styles/popups.css" rel="stylesheet" type="text/css" /> */}

        <div id="popup">
          <div id="popheader">Session Alert!</div>
          <div id="popbody">
            <p style={{ fontSize: '1.25em' }}> You only have {state.Warning} minutes left in your session. {(Username && Password) ? 'Do you need more time to fill out and submit your leave request?' : ''}</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p className="clear">&nbsp;</p>
            {(Username && Password)
              ? (
                <div id="button" style={{ float: 'right' }}>
                  <a
                    onClick={ReLogin}
                    href="This link deletes the currently selected item"
                    id="button"
                    style={{
                      width: '6em',
                      fontSize: '1.24em',
                      textAlign: 'center',
                      background: '#C12030',
                    }}
                  >
                    Login
                  </a>
                </div>
              )
              : ''}
          </div>
        </div>
      </Modal>
    )
  }
  return (
    <Modal status={state.TimeOut} closeControl={ClosePopUp}>
      {/* <link href="/styles/Structure.css" rel="stylesheet" type="text/css" />
      <link href="/styles/Styles.css" rel="stylesheet" type="text/css" />
      <link href="/styles/popups.css" rel="stylesheet" type="text/css" /> */}

      <div id="popup">
        <div id="popheader">Session Alert!</div>
        <div id="popbody">
          <p style={{ fontSize: '1.25em' }}>
            For security purposes your session has expired and you have been automatically logged out.  Click the link below to log back in
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <div id="button" style={{ float: 'right' }}>
            <a
              onClick={Logout}
              href="This link deletes the currently selected item"
              id="button"
              style={{
                width: '6em',
                fontSize: '1.24em',
                textAlign: 'center',
                background: '#C12030',
              }}
            >
              Login
            </a>
          </div>
        </div>
      </div>
    </Modal>
  )
}
